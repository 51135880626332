var site = site || {};

site.translations = site.translations || {};
site.translations.elc_general = site.translations.elc_general || {};

(function ($, site) {
  Drupal.behaviors.basicCarouselFormatterV2 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var $formatter = $('.js-basic-carousel-formatter-v2', context);
      var $carousel = $();

      $formatter.each(function () {
        var $thisFormatter = $(this);
        var $currentCarousel = $thisFormatter.find('.js-basic-carousel');
        var arrowsDiv = $thisFormatter.find('.js-carousel-controls');
        var dotsDiv = $thisFormatter.find('.js-carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        var $slickCounter = $thisFormatter.find('.js-basic-carousel-formatter-counter');
        var nextLabelDefault = site.translations.elc_general.next || 'next';
        var previousLabelDefault = site.translations.elc_general.previous || 'previous';
        var previousLabel = $currentCarousel.data('previousLabel') || previousLabelDefault;
        var nextLabel = $currentCarousel.data('nextLabel') || nextLabelDefault;
        var $prevArrow =
          '<div class="slick-prev-button slick--custom"><svg role="img" aria-label="' +
          previousLabel +
          '" class="icon icon--caret--left slick-prev"><use xlink:href="#caret--left"></use></svg></div>';
        var $nextArrow =
          '<div class="slick-next-button slick--custom"><svg role="img" aria-label="' +
          nextLabel +
          '" class="icon icon--caret--right slick-next"><use xlink:href="#caret--right"></use></svg></div>';
        var useResponsive = false;

        $carousel = $currentCarousel;

        var autoplay = $carousel.data('slidesAutoplay') || false;
        var infinite = $carousel.data('slidesInfinite');
        var slidesToShow = parseInt($currentCarousel.data('slidesToShow'));

        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          useResponsive = true;
        }
        var slidesToScroll = parseInt($currentCarousel.data('slidesToScroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }
        var pcDotsArrowsArgs = self.checkDotsAndArrows($currentCarousel.data('dotsArrowsPc'));
        var mobileDotsArrowsArgs = self.checkDotsAndArrows($currentCarousel.data('dotsArrowsMobile'));
        var settingsPlain = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: infinite,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows
        };
        var settingsResponsive = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: infinite,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          appendDots: dotsDiv,
          prevArrow: $prevArrow,
          nextArrow: $nextArrow,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        if (useResponsive === 'true') {
          $carousel.not('.slick-initialized').slick(settingsPlain);
        } else {
          $carousel
            .not('.slick-initialized')
            .on('init', function (event, slick) {
              self.renderSlickCounter(slick, $slickCounter);
            })
            .on('afterChange', function (event, slick) {
              self.renderSlickCounter(slick, $slickCounter);
            })
            .slick(settingsResponsive);
        }
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    },
    renderSlickCounter: function (slick, $slickCounter) {
      var currentSlide = slick.currentSlide + 1;

      $slickCounter.html(currentSlide + '/' + slick.slideCount);
    }
  };
})(jQuery, window.site || {});
